import React, { useState, useLayoutEffect } from "react"
import LayoutNoStripe from "../components/layoutNoStripe"
const Pt = ({ data, location }) => {
  const [isPricingTableSet, setPricingTableSet] = useState(true)
  const checkIfPricingTableParam = () => {
    const urlParams = new URLSearchParams(window.location.search)
    const pricingTableID = urlParams.get('pricingTableId')
    if(pricingTableID){
      setPricingTableSet(pricingTableID)
    } else {
      setPricingTableSet(false)
    }
  }
  useLayoutEffect(() => {
    if (typeof window !== "undefined" || window.document) {
      checkIfPricingTableParam()
      return
    }
  }, [])
  return (
    <LayoutNoStripe displayNavBorder location={location} noHeaderFooter>
       <script async src="https://js.stripe.com/v3/pricing-table.js"></script>
<stripe-pricing-table pricing-table-id={isPricingTableSet}
publishable-key="pk_live_LlCHGco3jggLnSWdhLCvfe59">
</stripe-pricing-table>
    </LayoutNoStripe>
  )
}

export default Pt

